import Blocks from '@components/containers/Blocks';
import Breadcrumbs from '@components/views/Breadcrumbs';
import Container from '@components/containers/Container';
import MainTemplate from '@components/containers/templates/MainTemplate';
import PageFallbackLoader from '@components/containers/PageFallbackLoader';
import getPostTypeStaticPaths from 'src/functions/wordpress/getPostTypeStaticPaths';
import getPostTypeStaticProps from 'src/functions/wordpress/getPostTypeStaticProps';
import { GetStaticPaths, GetStaticPropsContext } from 'next';
import { getFormattedSeoYoastBreadcrumbs } from 'src/utils/getFormattedSeoYoastBreadcrumbs';
import {
  isGutenbergBlockUsed,
  isGutenbergSecondaryHeroCarouselUsed,
} from 'src/utils/isGutenbergBlockUsed';
import { usePostTypeCollection } from 'src/services/api/queries/hooks/usePostTypeCollection';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import useRedirectTo404 from 'src/hooks/useRedirectTo404';

type SingleProductProps = {
  permalink: string;
};

const PAGES_NAMESPACE = '/produkty';

const postType = 'product';

export default function SingleProduct(props: SingleProductProps) {
  useRedirectTo404(props);
  const { isFallback } = useRouter();
  const pageFromSlugData = usePostTypeCollection(
    postType,
    { permalink: props.permalink },
    {
      enabled: !isFallback && !!props.permalink,
    },
  ).data?.data;

  if (!isFallback && pageFromSlugData && pageFromSlugData.length) {
    const page = pageFromSlugData[0];
    const isProductHero = isGutenbergBlockUsed(
      page.gutenberg_blocks,
      'acf/product-hero',
    );

    const isHeroCarousel = isGutenbergSecondaryHeroCarouselUsed(
      page.gutenberg_blocks,
    );
    const isSecondaryMenu = isGutenbergBlockUsed(
      page.gutenberg_blocks,
      'acf/secondary-menu',
    );

    return (
      <MainTemplate
        yoast_head={page.yoast_head}
        yoast_head_json={page.yoast_head_json}
        {...omit(page.meta_data, 'product_logo')}
        productColors={page.meta_data?.productColors || page.meta_data?.colors}
        productLogo={page?.featured_image?.url || page?.meta_data?.logo}
        permalink={props.permalink}
      >
        {!isProductHero && !isSecondaryMenu && !isHeroCarousel && (
          <Container>
            <Breadcrumbs
              items={getFormattedSeoYoastBreadcrumbs(page.yoast_head_json)}
            />
          </Container>
        )}

        <Blocks blocks={page.gutenberg_blocks} colors={page.meta_data.colors} />
      </MainTemplate>
    );
  }

  return <PageFallbackLoader />;
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  return await getPostTypeStaticProps(
    context,
    {
      postType,
    },
    { slugNamespace: PAGES_NAMESPACE },
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  return await getPostTypeStaticPaths({
    postType,
  });
};
