const findBlockWithSameName = (
  blocks: Array<any>,
  blockName: string,
): boolean =>
  !!blocks.find((block: any) => {
    if (block.blockName === blockName) return block.blockName === blockName;

    if (block.innerBlocks && block.innerBlocks.length > 0) {
      return findBlockWithSameName(block.innerBlocks, blockName);
    }

    return false;
  });

export const isGutenbergBlockUsed = (
  gutenberg_blocks: Array<any>,
  blockName: string,
) => findBlockWithSameName(gutenberg_blocks, blockName);

export const isGutenbergSecondaryHeroCarouselUsed = (
  gutenberg_blocks: Array<any>,
) =>
  !!gutenberg_blocks.find(
    (block: any) =>
      block.blockName === 'acf/hero-carousel' &&
      block.attrs.type === 'SECONDARY',
  );
