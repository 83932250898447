import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isProduction } from 'src/utils/environmental';

const useRedirectTo404 = (props: any) => {
  const { push } = useRouter();

  useEffect(() => {
    if (
      isUndefined(props.permalink) &&
      isUndefined(props.slug) &&
      isProduction
    ) {
      push('404');
    }
  }, [props.permalink, props.slug]);
};

export default useRedirectTo404;
